<template>
  <div id="app">
    
    <!-- <v-app-bar
      absolute
      color="white"
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
      style="position:fixed;background-color:white;"
    >
    
      <v-img
      lazy-src=".\assets\z.svg"
      max-height="100"
      max-width="50"
      src=".\assets\z.svg"
      style="margin-left:50px;"
      ></v-img>
      
       

    </v-app-bar> -->
  
   

    
    <router-view/>
  
  </div>
  
</template>
<script>
export default {
    name: 'App',    
    metaInfo() {
        return { 
            title: "Affiliate Online Registration – Invezt",
            meta: [
                { name: 'description', content:  'Complete the online registration with Invezt'},
                { property: 'og:title', content: "Affiliate Online Registration – Invezt"},
                { property: 'og:site_name', content: 'Invezt'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }
}
</script>

