<template>
<v-app>
  <div style="padding-top:60px;"></div>
    <v-card
    class="mx-auto"
   
    max-height="500"
    min-height="500"
    style="width:500px"
    outlined
    :loading='cardloader'
  >
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <template slot="progress">
          <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-img
          max-height="95"
          max-width="85"
          :src="require('@/assets/Invezt-01.svg')"
          style="   display: block;
                    margin-left:5px;
                "
          v-if="!$vuetify.theme.dark"
        ></v-img>
        <v-img
          max-height="95"
          max-width="85"
          :src="require('@/assets/InveztWhite.svg')"
          v-if="$vuetify.theme.dark"
          style="   display: block;
                    margin-left:5px;
                "
        ></v-img>
        
      
        <!-- <v-avatar
            color="primary lighten-2"
            class="subheading white--text"
            size="34"
            
            v-text="step+'/7'"
            style="font-size:10px;"
          ></v-avatar> -->
        <v-btn
              icon
              v-if="step != 1 && step != 8"
              @click="logout()"
            >
              <v-icon>mdi-logout</v-icon>
        </v-btn>
    
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <div style="padding-left:20px;padding-right:30px;">
            <p style="padding-left:8px;padding-top:20px;">Create your affiliate account in minutes</p>
            <h2 style="padding-left:8px;margin-top:-10px;">{{ currentTitle }}</h2>
            
            <p  v-if="!otpdisp" style="color:grey;padding-left:10px;padding-top:20px;">OTP Sent to <strong>+91 {{ mobile_num }} </strong> <a style="font-size:14px;" @click="changeMobileNumber()">(change)</a></p>
      
                  <div class="row" v-if="mobdisp" style="padding:0;padding-top:30px">
                      <div class="col-9" style="padding-right:0;">
                          
                          <v-text-field
                              type="number"
                              class="inputPrice mx-2"
                              v-model="mobile_num"
                              label="Mobile number"
                              placeholder="Mobile number"
                              required
                              prefix="+91"  
                              counter="10"
                              v-on:keyup="MobNumberValidation()"
                              tabindex="1"
                              style="white-space: nowrap;"
                              :error-messages="numberErrors"
                              @input="$v.mobile_num.$touch()"
                              @blur="$v.mobile_num.$touch()"
                              
                          >
                          </v-text-field>
                      </div>
                      <div class="col-3" style="padding-left:0;">
                          <v-btn color="primary"  elevation="2" small  class="py-4 my-4 otpbtn" block  @click="verifyMobile()" tabindex="2">Verify
                          </v-btn>
                          
                          
                      </div>
                      <!-- <span v-if="errorMobNumber" style="font-size:12px;padding-left:10px;color:red">Please enter valid Mobile<br></span> -->
                    <p style="padding-left:20px;font-size:12px;width:100%">By submitting your contact details, you authorise Invezt to call or text you for the purpose of account opening even though you may be registered on DND.</p>
                  </div>
                  <div class="row" v-if="!otpdisp">
                        
                      <div class="col-9" style="padding-right:0;">
                      <v-text-field
                          class="inputPrice mx-2"
                          v-model="otp"
                          label="OTP"
                          type="number"
                          placeholder="Enter OTP"
                          v-on:keyup="OtpValidation()"
                          :error-messages="otpErrors"
                          @input="$v.otp.$touch()"
                          @blur="$v.otp.$touch()"
                          counter="4"
                          required
                      >
                      </v-text-field>
                      
                      </div>
                      <div class="col-3" style="padding-left:0;">
                            <v-btn color="primary"  depressed  elevation="2" small block  class="py-4 my-4 otpbtn" @click="otpVerify()" >Verify OTP
                            </v-btn>
                      </div>
                  </div>
                  
                  <span v-if="timerEnabled" style="font-size:14px;padding-left:10px;">{{ timerCount}} Sec</span>
                  <span v-if="resendText" style="font-size:14px;padding-left:10px;">OTP didn't receive it <a @click="mobOtpResend()">(resend)</a></span>
                  
                  <p v-if="!otpdisp" style="padding-left:10px;font-size:12px;padding-top:10px;">By submitting your contact details, you authorise Zebu to call or text you for the purpose of account opening even though you may be registered on DND.</p>

                  <div class="row" v-if="emailDisp" style="padding-top:20px;">
                      <div class="col-9" style="padding-right:0">
                        <v-text-field
                                class="mx-2"
                                v-model="email"
                                label="Email ID"
                                :error-messages="emailErrors"
                                @input="$v.email.$touch()"
                                @blur="$v.email.$touch()"
                                required
                        ></v-text-field>
                      </div>
                      <div class="col-3" style="padding-left:0;">
                        <v-btn color="primary"  depressed  elevation="2" small block class="py-4 my-4 otpbtn" v-if="checkbox"  @click="verifyEmail()" :loading="loading"
                                    :disabled="loading"
                        >Verify Email
                        </v-btn>
                        <v-btn color="primary"  disabled  elevation="2" small block class="py-4 my-4 otpbtn" v-if="!checkbox"  @click="verifyEmail()" :loading="loading"
                          
                        >Verify Email
                        </v-btn>
                      </div>
                  </div>
                  <p  v-if="emailOtpDisp" style="color:grey;padding-top:20px;padding-left:10px;">Code sent to <span style="color:black;">{{ email }} </span> <a style="font-size:14px;" @click="changeEmailId()">(change)</a></p> 
                  <div class="row" v-if="emailOtpDisp" >
                    
                      <div class="col-9">
                      <v-text-field
                          class="mx-3 inputPrice"
                          type="number"
                          v-model="otpemail"
                          label="Email OTP"
                          placeholder="Enter Code"
                          v-on:keyup="OtpeValidation()"
                          :error-messages="eotpErrors"
                          @input="$v.otpemail.$touch()"
                          @blur="$v.otpemail.$touch()"
                          counter="4"
                          required
                      >
                      </v-text-field>
                      </div>
                      <div class="col-3" style="padding-left:0;">
                            <v-btn color="primary"  depressed  elevation="2" small block class="py-4 my-4 otpbtn" v-if="!isShowing"  @click="otpVerifyemail()" :loading="loading"
                                  :disabled="loading">Verify Code
                            </v-btn>
                            
                      </div>
                  </div>
                  <v-checkbox
                          class="mx-2"
                          v-model="checkbox"
                          label="I declare that the given email ID is mine"
                          required
                          @change="$v.checkbox.$touch()"
                          @blur="$v.checkbox.$touch()"
                          v-if="emailDisp"
                          style="font-size:12px;"
                  ></v-checkbox>
                  <p v-if="emailDisp || emailOtpDisp" style="padding-left:10px;font-size:12px;">By submitting your contact details, you authorise Invezt to call or text you for the purpose of account opening even though you may be registered on DND.</p>
                      
          </div>
          <v-spacer></v-spacer>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
              <div v-if="panDetaildisp" style="padding-left:20px;padding-right:30px;padding-top:20px;">
                        <h2 style="padding-left:8px;margin-top:-10px;">We'll begin with your PAN First</h2>
                        <p style="padding-left:8px;padding-top:20px;">Insert Your PAN and Date of Birth as mentioned on your PAN Card</p>
                        
                        <div class="row">
                          
                          <div class="col-9" style="padding-right:0">
                            <v-text-field
                            v-model="panInput"
                            class="mx-2"
                            label="PAN"
                            counter="10"
                            required
                            @keyup="panInput=panInput.toUpperCase()"
                            v-on:keyup="panNumberValidation()"
                            :disabled="panFetchDetail"
                            :error-messages="panErrors"
                            @input="$v.panInput.$touch()"
                            @blur="$v.panInput.$touch()"
                            ></v-text-field>
                          </div>
                          <div class="col-3" style="padding-left:0;">
                          <v-btn color="primary"  depressed  elevation="2" small block class="py-4 my-4 otpbtn" v-if="!isShowing"  
                                      @click="panFetch()"
                                      :disabled="cardloader || panFetchDetail || panInputLength"
                                      >Fetch PAN 
                          </v-btn>
                          </div>
                        </div>
                        <h2 class="mx-2" style="padding-top:20px;" v-if="panFetchDetail">{{ name }}</h2>
                        <p style="padding-left:8px;padding-top:20px;font-size:12px;">Your Account Name will be taken as mentioned in the Income Tax Database</p>
                        <!-- <v-btn color="primary" class="otpbtn" @click="aadharInit()" :loading="loading"
                                      :disabled="loading" style="display:none;"
                                      >Confirm
                        </v-btn> -->
                        
                    </div>
                    
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <div style="padding-left:20px;padding-right:30px;">
            <p v-if="KycFetchDisp" style="color:grey;padding-top:50px;">Data fetched from your Aadhar 
             </p>
                  <v-row
                    v-if="kycLoader"
                    class="fill-height"
                    style="margin-top:100px;"
                  >
                    <v-col
                      class="text-subtitle-1 text-center"
                      cols="12"
                    >
                      Fetching your data from DigiLocker
                    </v-col>
                    <v-col cols="12">
                      <v-progress-linear
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                        class="mx-auto"
                        style="margin-top:-20px;width:40%"
                      ></v-progress-linear>
                    </v-col>
                  </v-row>
                  <div v-if="!KycFetchDisp && !kycLoader">
                  <h2 style="padding-left:8px;">Share Aadhar details from Digilocker  <v-icon @click.stop="dialog = true">
                  mdi-information
                </v-icon></h2>
                   <v-dialog
                      v-model="dialog"
                      max-width="290"
                    >
                      <v-card>
                        <v-card-title style="width:100%;">Info <v-icon style="margin-left:auto;" @click.stop="dialog = false">mdi-close</v-icon></v-card-title>
                        <v-card-text style="text-align:justify;">
                          Do not worry if you have never registered with Digilocker. Click on the button "Connect To Digilocker". Digilocker will open as a pop-up on your screen where provide your Aadhaar number and authenticate with OTP. You will then be required to set your PIN and give your consent to provide your Aadhaar document to us for KYC. Your 12 digit Aadhar number is never fetched, stored, revealed or collected. The Aadhaar and the PAN should belong to you.
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  <div :value="digiInformationShow" style="padding-top:60px;"></div>
                  <v-alert
                    color="blue-grey"
                    dark
                    dense
                    type="info"
                    prominent
                    style="font-size:10px;"
                    :value="digiInformationShow"
                    
                  >
                    
                  </v-alert>
                  <span style="padding-left:10px;"><v-btn color="primary" @click="connectDigio()">Connect DigiLocker</v-btn></span>
                  <p style="padding-left:10px;font-size:12px;padding-top:50px;text-align:justify">I agree that have read the Aadhaar user consent document and voluntarily consent to share my Aadhaar information with zebu for conducting KYC for opening a trading and Demat account adhering to KRA regulations (2011) and PMLA guidelines (2002). </p>
                  <!-- <v-btn color="primary" @click="e6 = 4">Continue</v-btn> -->
                  </div>
                  
                  <div v-if="KycFetchDisp" style="padding:10px;padding-top:0;margin-top:-12px">
                      
                      <v-card-title>{{ name }} <span style="font-size:14px;margin-left:10px;">({{ aadharNo }})</span></v-card-title>
                      <v-card-subtitle>{{ gender }} | {{ dob}}</v-card-subtitle>
                      
                      <v-card-text v-if="gender == 'Male'"><strong>S/o</strong> {{ fatherName}}</v-card-text>
                      <v-card-text v-if="gender != 'Male'"><strong>D/o</strong> {{ fatherName}}</v-card-text>
                      <v-card-text style="padding-top:0;margin-top:-12px">{{ add1 }} {{add2 }} {{add3 }} {{add4 }}</v-card-text>
                      <v-card-text style="padding-top:0;margin-top:-12px">{{district}} {{ state   }}  - {{ pinCode }} {{ country}}</v-card-text>
                      
                    
                     
                  </div>
          </div>
        </v-window-item>

        <v-window-item :value="4">
          <div class="pa-4" style="padding-left:20px;padding-right:30px;">
            <h2 style="padding-left:8px;margin-top:-10px;">Bank Account Details</h2>
            <p style="padding-left:8px;padding-top:20px;">Provide Bank account details wherein you are sole/primary holder</p>
            <v-text-field
                                      class="mx-2"
                                      v-model="ifsc"
                                      label="IFSC Code"
                                      @input="ifscFetchDetails"
                                      @keyup="ifsc=ifsc.toUpperCase()"
                                      v-on:keyup="ifscValidation()"
                                      :error-messages="ifscErrors"
                                      @blur="$v.ifsc.$touch()"
                                      counter="11"
                                      required
                ></v-text-field>
                <span v-if="bank_name">
                  <strong style="padding-left:8px"> {{ bank_name }} </strong>
                  <p style="font-size:12px;padding-left:8px">{{ bank_branch }} | {{ bank_micr}}</p>
                </span>
                <v-text-field
                                      class="mx-2 inputPrice"
                                      type="number"
                                      v-model="accNo"
                                      label="Account Number"
                                      required
                                      :error-messages="accErrors"
                                      @input="$v.accNo.$touch()"
                                      @blur="$v.accNo.$touch()"
                ></v-text-field> 
                
          </div>
        </v-window-item>

        <v-window-item :value="5">
          <div class="pa-4" style="padding-left:20px;padding-right:30px;">
            <label class="px-3">Exchange Selection</label>
                     <v-row style="padding-left:10px;">
                        <v-col
                          cols="6"
                          sm="4"
                          md="2"
                        >
                          <v-checkbox
                            v-model="exchangeSelect"
                            label="NSE"
                            color="blue"
                            value="NSE"
                            :rules="rules"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="6"
                          sm="4"
                          md="2"
                        >
                          <v-checkbox
                            v-model="exchangeSelect"
                            label="BSE"
                            color="blue"
                            value="BSE"
                            :rules="rules"
                            hide-details
                          ></v-checkbox>
                          
                        </v-col>
                        <v-col
                          cols="6"
                          sm="4"
                          md="2"
                        >
                          <v-checkbox
                            v-model="exchangeSelect"
                            label="MCX"
                            color="blue"
                            value="MCX"
                            :rules="rules"
                            hide-details
                          ></v-checkbox>
                    
                        </v-col>
                    </v-row>
                    <v-text-field
                                          class="mx-3 inputPrice"
                                          style= "padding-top:25px;"
                                          type="number"
                                          v-model="tradingExperience"
                                          label="Years of trading experience"
                                          :error-messages="texpErrors"
                                          @input="$v.tradingExperience.$touch()"
                                          @blur="$v.tradingExperience.$touch()"
                                          required
                    ></v-text-field>
                    <!-- <v-row align="center" class="mx-2">
                      <v-checkbox
                        v-model="enabledRegisteredAP"
                        color="blue"
                        hide-details
                        class="shrink mr-2 mt-0"
                      ></v-checkbox>
                      <v-text-field
                          
                        :disabled="!enabledRegisteredAP"
                        label="Are you registered (AP) with any other Broker"
                        v-model="regMemberName"
                        placeholder="Please specify the Broker name"
                        :error-messages="regMemberNameErrors"
                        @input="$v.regMemberName.$touch()"
                        @blur="$v.regMemberName.$touch()"
                        required
                        class="pt-8"
                        
                      ></v-text-field>
                    </v-row> -->
                    
          
                    <div class="row pt-2" style="padding:0;margin-top:-15px">
                      <div class="col-5">
                        
                        <v-select
                          v-model="qualification"
                          class="mx-3"
                          :items="qualificationList"
                          label="Qualification"
                          :error-messages="quaErrors"
                          @input="$v.qualification.$touch()"
                          @blur="$v.qualification.$touch()"
                          required
                        ></v-select>
                      </div>
                      <div class="col-6">
                      
                          <v-select
                          v-model="occupation"
                          style= width:270px
                          :items="occupationList"
                          label="Occupation"
                          :error-messages="occErrors"
                          @input="$v.occupation.$touch()"
                          @blur="$v.occupation.$touch()"
                          required
                        ></v-select>
                      </div> 
                    </div>
                    
          </div>
        </v-window-item>

        <v-window-item :value="6">
          <div class="pa-4" style="padding-left:20px;padding-right:30px;">
              <h2 style="padding-left:8px;margin-top:-10px;padding-top:30px;">Upload Your Document</h2>
                        
              <v-file-input
                    chips
                    counter
                    show-size
                    small-chips
                    truncate-length="21"
                    label="Aadhar"
                    v-model="aadharFile"
                    :error-messages="adharErrors"
                    @input="$v.aadharFile.$touch()"
                    @blur="$v.aadharFile.$touch()"
                    accept="image/png, image/jpg, image/jpeg"
                    required
                  ></v-file-input>
                  <v-file-input
                    chips
                    counter
                    show-size
                    small-chips
                    truncate-length="21"
                    label="PAN"
                    v-model="panFile"
                    accept="image/png, image/jpg, image/jpeg"
                    :error-messages="panfErrors"
                    @input="$v.panFile.$touch()"
                    @blur="$v.panFile.$touch()"
                    required 
                  ></v-file-input>
                  <v-file-input
                    chips
                    counter
                    show-size
                    small-chips
                    truncate-length="21"
                    label="Bank Proof"
                    v-model="bankFile"
                    accept="image/png, image/jpg, image/jpeg"
                    :error-messages="bankfErrors"
                    @input="$v.bankFile.$touch()"
                    @blur="$v.bankFile.$touch()"
                    required
                  ></v-file-input>
                  
          </div>
        </v-window-item>

        <v-window-item :value="7">
          <div class="pa-4 text-center">
              <div style="padding-top:80px;"></div>
              <v-btn
                  color="primary"
                  @click="digioEsign()"
                  v-if="checkboxEsign"
                >
                  Aadhar E-sign
                </v-btn>
                <v-btn
                  color="primary"
                  v-if="!checkboxEsign"
                  disabled
                >
                  Aadhar E-sign
                </v-btn>
                <v-checkbox class="mx-2" v-model="checkboxEsign">
                  <template v-slot:label>
                    <div style="text-align:justify;">
                      I confirm to have read and understood the contents of the Annexure documents &amp; Standing Instruction
                    </div>
                  </template>
                </v-checkbox>
          </div>
        </v-window-item>
        <v-window-item :value="8">
          <div class="pa-4 text-center">
              <v-card-text><h2>Congratulations!</h2></v-card-text>
              <v-icon class="mx-auto" size="70" color="green" width="250">mdi-checkbox-marked-circle</v-icon>
              <v-card-text style="padding-top:50px;">Thank you for becoming an affiliate of Invezt.</v-card-text>
              <v-card-text style="margin-top:-30px;">Our team will reach you shortly.</v-card-text>
          </div>
        </v-window-item>

      </v-window>
      <span v-if="step !=1" style="position:absolute;bottom:0;width:100%">
          <v-divider></v-divider>
          <v-card-actions>
            
            <v-btn text v-if="step !=1 && step!= 2" @click="step--">Back</v-btn>
            <v-btn text v-if="step !=1 && step == 2" @click="logout()">Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="step==2 && panFetchDetail" class="otpbtn" @click="aadharInit()">Confirm</v-btn>
            <v-btn color="primary" v-if="step==3 && KycFetchDisp" @click="step = 4">Confirm</v-btn>
            <v-btn color="primary" v-if="step==4" @click="bankDetailUpdate()">Continue</v-btn>
            <v-btn color="primary" v-if="step==5" @click="segmentSelection()">Continue</v-btn>
            <v-btn color="primary" v-if="step==6" @click="fileUpload()" :disabled="cardloader">Continue</v-btn>
          </v-card-actions>
      </span>
    </v-card>
    <p style="text-align:center;font-size:12px;padding-top:10px;">Need Help? Write to <a href="mailto:askus@invezt.in" target="_blank">askus@invezt.in</a> or call <a href="tel:9380508010" target="_blank">+91 9380508010</a></p>
    <div class="d-flex justify-center" style="item-align:center;width:100%;"><DarkModeSwitch @switched="onSwitched" :initialState="isDarkModeEnabled" /></div>
    
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      class="text-align:center"
    >
      <v-icon
        dark
        right
        >
          mdi-checkbox-marked-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

   </v-app>
</template>
<script>
           history.pushState(null, null, location.href);
            window.onpopstate = function () {
                history.go(1);
};
</script>
<script src="https://app.digio.in/sdk/v8/digio.js"></script>

<script>
  import axios from 'axios';
  import { validationMixin } from 'vuelidate'
  import { required, maxLength, email } from 'vuelidate/lib/validators'
  import DarkModeSwitch from 'vue-dark-mode-switch'
  import 'vue-dark-mode-switch/dist/vue-dark-mode-switch.css'
  const script = document.createElement('script')
  script.src = 'https://app.digio.in/sdk/v8/digio.js'
  document.body.appendChild(script)

  export default {
                        mixins: [validationMixin],
                        validations: {
                          mobile_num: { required },
                          otp: { required },
                          email: { required, email },
                          otpemail: { required },
                          panInput : {required },
                          ifsc : { required },
                          accNo : { required },
                          aadharFile:{required},
                          panFile:{required},
                          bankfile:{required},
                          tradingExperience:{required},
                          qualification:{required},
                          occupation:{required},
                          checkbox: {
                                      checked (val) {
                                        return val
                                      },
                            },
                          //regMemberName:{required},
                        },
                        data: () => ({
                        loader:null,
                        panLoader:null,
                        loading:false,
                        mobile_num:null,
                        //errorMobNumber:false,
                        logstatus:false,
                        reference:null,
                        timerCount:120,
                        timerEnabled:false,
                        resendText:false,
                        email:null,
                        emailDisp:false,
                        emailOtpDisp:false,
                        otpemail:null,
                        otp:null,
                        checkbox:false,
                        invalid:null,
                        text: `You will receive an OTP on your number`,
                        loadertext:"Verify",
                        timeout: 3000,
                        otpdisp: true,
                        mobdisp:true,
                        isShowing:false,
                        activePicker: null,
                        date: null,
                        menu: false,
                        panDetaildisp: true,
                        panFetchDetail: false,
                        panInput:null,
                        accNo: null,
                        ifsc: null,
                        bank_name: null,
                        bank_branch: null,
                        bank_micr: null,
                        enabledRegisteredAP: false,
                        regMemberName:null,
                        exchangeSelect: ['NSE'],
                        tradingExperience: null,
                        qualificationList:['Highschool' , 'Graduation' , 'Post Graduation' , 'Doctrate' , 'Prof. Degree' , 'Under High School' , 'Illiterate-Other'] ,
                        occupationList:['Private Sector','Public Sector','Government Service','Business','Professional Agriculturist','Retired','Housewife','Student','Forex Dealer','Other'],      
                        qualification:null,
                        occupation:null,
                        errorDescPAN:false,
                        KycFetchDisp:false,
                        dob:null,
                        aadharNo:null,
                        add1:null,
                        add2:null,
                        add3:null,
                        add4:null,
                        address:null,
                        country:null,
                        district:null,
                        state:null,
                        pinCode:null,
                        fatherName:null,
                        gender:null,
                        name:null,
                        panFile:null,
                        aadharFile:null,
                        bankFile:null,
                        kycLoader:false,
                        checkboxEsign:false,
                        digioFile:null,
                        digioMail:null,
                        ekycStatus:false,
                        step: 1,
                        digiInformationShow:false,
                        dialog:false,
                        isDarkModeEnabled: false,
                        errorOTPDisp:false,
                        erroreOTPDisp:false,
                        cardloader:false,
                        ifscErrorDesc:false,
                        snackbar: false,
                        toasttext: 'null',
                        timeout: 5000,
                        panInputLength:false,
                        }),
                        methods:{   
                                    verifyMobile(){
                                        this.$v.$touch()
                                        
                                        this.cardloader = true
                                        //this.errorMobNumber=false
                                        //console.log("Mobile Number Sent :"+this.mobile_num,this.mobile_num.length)
                                        var hash = new Date().toLocaleString()
                                        var axiosThis = this
                                        localStorage.setItem('sesslog',btoa(hash))
                                        if (this.mobile_num)
                                        { 
                                            this.$v.$reset()
                                            if (this.mobile_num.length == 10 && this.mobile_num.length != 0){
                                                var config = {
                                                  method: 'post',
                                                  url: 'https://be.zebull.in/mobile?mob='+this.mobile_num+'&hash='+btoa(hash),
                                                  headers: { }
                                                };

                                                axios(config)
                                                .then(function (response) {
                                                                axiosThis.toasttext = 'OTP Sent';
                                                                axiosThis.snackbar = true;
                                                                axiosThis.mobdisp = false;
                                                                axiosThis.otpdisp = false
                                                                axiosThis.timerEnabled = true
                                                                
                                                                axiosThis.loader = 'loading'
                                                                console.log(JSON.stringify(response.data));
                                                                axiosThis.cardloader=false
                                                })
                                                .catch(function (error) {
                                                  console.log(error);
                                                  axiosThis.cardloader=false
                                                });
                                            }
                                        }
                                        else{
                                          this.cardloader=false;
                                        }


                                    },
                                    MobNumberValidation(){
                                      if (this.mobile_num){
                                              console.log("I'm Innn")
                                              var newVal = this.mobile_num.replace('-','');
                                              console.log("New Val:",newVal);
                                              if(newVal.length > 10){
                                                
                                                  this.mobile_num = newVal.slice(0,10);
                                              }
                                      }
                                    },
                                    panNumberValidation(){
                                      this.errorDescPAN=false
                                      if (this.panInput){
                                              if(this.panInput.length == 10 || this.panInput.length == 11){
                                                  this.panInputLength = false;
                                              }
                                              else{
                                                   this.panInputLength = true;
                                              }
                                              var newVal = this.panInput.replace('-','');
                                              console.log("New Val:",newVal);
                                              if(this.panInput.length > 10){
                                                 
                                                  this.panInput = newVal.slice(0,10);
                                              }
                                              
                                      }
                                    },
                                    ifscValidation(){
                                      //this.ifscErrorDesc=false
                                      
                                      if (this.ifsc){
                                              var newVal = this.ifsc.replace('-','');
                                              //console.log("New Val:",newVal);
                                              if(this.ifsc.length > 11){
                                                  this.ifsc = newVal.slice(0,11);
                                              }
                                      }
                                    },
                                    mobOtpResend(){
                                            var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/resendmobotp?mob='+this.mobile_num+'&hash='+localStorage.getItem('sesslog'),
                                              headers: { }
                                            };
                                            var axiosThis = this
                                            axios(config)
                                            .then(function (response) {
                                              axiosThis.toasttext = 'OTP Resent';
                                              axiosThis.snackbar = true;
                                              axiosThis.timerEnabled =true
                                              axiosThis.timerCount = 121
                                              axiosThis.resendText=false
                                              console.log(JSON.stringify(response.data));
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                            });
                                    },
                                    changeMobileNumber(){
                                        this.mobdisp=true
                                        this.otpdisp=true
                                        this.mobile_num=null
                                        this.loadertext="Verify"
                                        this.timerEnabled =false
                                        this.timerCount = 121
                                        this.resendText=false
                                    },
                                    OtpValidation(){
                                      if(this.otp){ 
                                        this.errorOTPDisp = false;
                                        var newVal = this.otp.replace('-','');
                                        console.log("New Val:",newVal);
                                        if(newVal.length > 4){
                                            this.otp = newVal.slice(0,4);
                                        }
                                      }
                                    },
                                    OtpeValidation(){
                                      if(this.otp){ 
                                        this.erroreOTPDisp = false;
                                        var newVal = this.otpemail.replace('-','');
                                        console.log("New Val:",newVal);
                                        if(newVal.length > 4){
                                            this.otpemail = newVal.slice(0,4);
                                        }
                                      }
                                    },
                                    otpVerify(){
                                        this.$v.$touch()
                                        this.cardloader=true
                                        this.errorOTPDisp=false;
                                        if(this.otp){ 
                                        if(this.otp.length == 4){ 
                                        
                                        this.$v.$reset()
                                        var config = {
                                          method: 'post',
                                          
                                          url: 'https://be.zebull.in/otpVerifyMobile?mob='+this.mobile_num+'&code='+this.otp+'&hash='+ localStorage.getItem('sesslog'),
                                          headers: { }
                                        };
                                        var axiosThis = this
                                        axios(config)
                                        .then(function (response) {
                                          console.log(JSON.stringify(response.data));
                                          var responseOTP = response.data
                                          if (responseOTP['otp'] == 'success')
                                          {
                                            axiosThis.errorOTPDisp = false
                                            axiosThis.toasttext = "OTP Verified";
                                            axiosThis.snackbar = true;
                                            console.log(responseOTP)
                                            localStorage.setItem('hash',btoa(axiosThis.mobile_num))
                                            if (responseOTP['ekycstatus'] == 'Yes'){
                                                  axiosThis.step=8;
                                                  axiosThis.ekycStatus=true;
                                                  axiosThis.cardloader=false;
                                            }
                                            else if (responseOTP['emailVerify'] == 'Yes')
                                            {   
                                                if(responseOTP['PAN'] && responseOTP['name']){
                                                  axiosThis.panInput = responseOTP['PAN']
                                                  axiosThis.name=responseOTP['name']
                                                  axiosThis.dob = responseOTP['dob']
                                                  axiosThis.aadharNo = responseOTP['aadharNo']
                                                  axiosThis.add1 = responseOTP['add1']
                                                  axiosThis.add2 = responseOTP['add2']
                                                  axiosThis.add3 = responseOTP['add3']
                                                  axiosThis.add4 = responseOTP['add4']
                                                  axiosThis.address = responseOTP['address']
                                                  axiosThis.state = responseOTP['state']
                                                  axiosThis.district = responseOTP['district']
                                                  axiosThis.country = responseOTP['country']
                                                  axiosThis.gender = responseOTP['gender']
                                                  axiosThis.pinCode = responseOTP['pincode']
                                                  axiosThis.bank_name = responseOTP['bankname']
                                                  axiosThis.accNo = responseOTP['accno']
                                                  axiosThis.ifsc = responseOTP['ifsc']
                                                  axiosThis.bank_micr = responseOTP['micr']
                                                  axiosThis.bank_branch =responseOTP['branch']
                                                  axiosThis.digioFile =responseOTP['fileid']
                                                  axiosThis.ekycStatus =responseOTP['ekycstatus']
                                                  var exchangeSelectList = ['NSE']
                                                  if (responseOTP['NSEseg'] == 'NSE'){
                                                    exchangeSelectList.push('NSE');
                                                  }
                                                  if(responseOTP['BSEseg'] == 'BSE'){
                                                    exchangeSelectList.push('BSE');
                                                  }
                                                  if(responseOTP['MCXseg'] == 'MCX'){
                                                    exchangeSelectList.push('MCX');
                                                  }

                                                  axiosThis.exchangeSelect = exchangeSelectList;

                                                  axiosThis.tradingExperience = responseOTP['tradingExp']
                                                  // console.log('Nan Tha :',responseOTP['regMember'])
                                                  // if(responseOTP['regMember'] != "null" && responseOTP['regMember'] != null){
                                                  //   axiosThis.enabledRegisteredAP = true
                                                  //   axiosThis.regMemberName = responseOTP['regMember']
                                                  // }
                                                  // else{
                                                  //   axiosThis.enabledRegisteredAP = false
                                                  //   axiosThis.regMemberName = null
                                                  // }
                                                  axiosThis.qualification = responseOTP['qualification']
                                                  axiosThis.occupation = responseOTP['occupation']
                                                  axiosThis.fatherName = responseOTP['fathername']
                                                  //axiosThis.panDetaildisp = false
                                                  axiosThis.panFetchDetail = true
                                                  axiosThis.logstatus = true
                                                  //axiosThis.cardloader=false;
                                                }
                                                axiosThis.cardloader=false;
                                                axiosThis.step = 2
                                            }
                                            else{
                                              axiosThis.otpdisp=true
                                              axiosThis.timerEnabled =false
                                              axiosThis.resendText=false
                                              axiosThis.timerCount = 121
                                              axiosThis.logstatus = true
                                              axiosThis.emailDisp=true
                                              axiosThis.cardloader=false;

                                            }
                                          }
                                          else{
                                            axiosThis.otp=null
                                            axiosThis.$v.$reset()
                                            console.log('OTP Verify False');
                                            axiosThis.errorOTPDisp=true
                                            axiosThis.cardloader=false;
                                          }
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                                    }
                                        else{
                                          this.otp = null
                                          this.errorOTPDisp=true;
                                          this.cardloader=false;
                                        }
                                        }
                                        else{
                                          this.cardloader=false;
                                          
                                        }

                                    },
                                    verifyEmail(){
                                        
                                        this.$v.$touch()
                                        //this.cardloader = true
                                        if (this.email.includes('@')&&this.email.includes('.'))
                                        { 

                                        this.cardloader = true;
                                        this.$v.$reset()
                                        var hash = new Date().toLocaleString()
                                        localStorage.setItem('sesslog',btoa(hash))
                                        var config = {
                                          method: 'post',
                                          url: 'https://be.zebull.in/email?mob='+this.mobile_num+'&email='+this.email+'&hash='+btoa(hash),
                                          headers: { }
                                        };
                                        var axiosThis = this
                                        axios(config)
                                        .then(function (response) {
                                          console.log(JSON.stringify(response.data));
                                          //axiosThis.cardloader= false
                                          axiosThis.toasttext = "OTP sent to Email";
                                          axiosThis.snackbar = true;
                                          this.$v.$reset()
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                        });

                                        this.otpdisp=true
                                        this.timerEnabled =false
                                        this.timerCount = 121
                                        this.emailDisp = false
                                        this.emailOtpDisp = true
                                        this.cardloader = false
                                        }
                                        else{
                                          this.cardloader = false
                                        }
                                    },
                                    otpVerifyemail(){
                                        this.cardloader = true
                                        this.$v.$touch()
                                        if(this.otpemail){ 
                                        if(this.otpemail.length == 4){

                                            this.$v.$reset()
                                            var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/otpVerifyEmail?mob='+this.mobile_num+'&email='+this.email+'&code='+this.otpemail+'&hash='+ localStorage.getItem('sesslog'),
                                              headers: { }
                                            };
                                            var axiosThis = this
                                            axios(config)
                                            .then(function (response) {
                                              console.log(JSON.stringify(response.data));
                                              var responseOTPemail = response.data
                                              if (responseOTPemail['status'] == 'Verified'){
                                                axiosThis.toasttext = "Email OTP is Verified";
                                                axiosThis.snackbar = true;
                                                axiosThis.erroreOTPDisp = false
                                                axiosThis.step=2
                                                axiosThis.cardloader = false
                                                this.$v.$reset()
                                              }
                                              else{
                                                axiosThis.otpemail = null
                                                axiosThis.erroreOTPDisp = true
                                                axiosThis.cardloader = false
                                              }
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                            });
                                        }
                                        else{
                                          this.otpemail = null
                                          this.erroreOTPDisp=true;
                                          this.cardloader = false
                                        }
                                        }
                                        else{
                                          this.cardloader = false
                                        }
                                    },
                                    save (date) {
                                      this.$refs.menu.save(date)
                                    },
                                    panFetch(){
                                        this.$v.$touch()
                                        
                                        if(this.panInput){  
                                            this.cardloader =true
                                            this.$v.$reset()
                                            var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/panCheck?PAN='+this.panInput+'&mob='+this.mobile_num,
                                              headers: { }
                                            };
                                            var axiosThis = this
                                            axios(config)
                                            .then(function (response) {
                                              console.log(JSON.stringify(response.data));
                                              var responsePANfetch =  response.data
                                              if (responsePANfetch["Name"] == 'No Data'){
                                                axiosThis.cardloader =false
                                                console.log("Not verified")
                                                axiosThis.errorDescPAN=true
                                                axiosThis.panInput = null
                                              }
                                              else{
                                                console.log("PAN Verified")
                                                axiosThis.cardloader =false
                                                axiosThis.name=responsePANfetch["Name"]
                                                //axiosThis.panDetaildisp = false
                                                axiosThis.panFetchDetail = true
                                              }
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                            });

                                        }
                                        
                                    },
                                    changeEmailId(){
                                        this.emailDisp=true
                                        this.emailOtpDisp = false
                                        this.email= null
                                    },
                                    aadharInit(){
                                          if(this.aadharNo){
                                            this.kycLoader=false
                                            this.KycFetchDisp=true
                                            this.step=3
                                          }
                                          else{
                                            this.step=3
                                          }
                                    },

                                    async ifscFetchDetails(){
                                        //console.log("Ifsc:"+this.ifsc.length);

                                        if (this.ifsc.length >= 11 ){
                                            var ifsvc = this.ifsc;
                                            if(this.ifsc.length > 11){
                                              ifsvc = this.ifsc.slice(0,11);
                                            }
                                            console.log("IFSC :",ifsvc);
                                            const axios = require('axios');
                                            var config = {
                                              method: 'get',
                                              url: 'https://ifsc.razorpay.com/'+ifsvc,
                                            };
                                            var axiosThis=this
                                            await axios(config)
                                            .then(function (response) {
                                              //console.log(JSON.stringify(response.data));
                                              var req_data =response.data;
                                              var bank_name = req_data['BANK'];
                                              var branch = req_data['BRANCH'];
                                              var micr = req_data['MICR'];
                                              console.log(bank_name,branch,micr)
                                              axiosThis.bank_name = bank_name;
                                              axiosThis.bank_branch = branch;
                                              axiosThis.bank_micr =  micr;
                                              axiosThis.ifscErrorDesc = false
                                            })
                                            .catch(function (error) {
                                              console.log("IFSC errorr catched!")
                                              axiosThis.ifscErrorDesc =true
                                            });

                                        }
                                        else{
                                              this.bank_name = null;
                                              this.bank_branch = null;
                                              this.bank_micr =  null;
                                        }

                                    },
                                    segmentSelection(){
                                      this.$v.$touch()
                                      console.log(this.mobile_num , this.exchangeSelect,this.exchangeSelect.length , this.regMemberName , this.qualification != null , this.occupation != null)
                                      if(this.mobile_num && this.exchangeSelect.length != 0 && this.tradingExperience && this.qualification != null && this.occupation != null){ 
                                        
                                        this.$v.$reset()
                                        var config = {
                                          method: 'post',
                                          url: 'https://be.zebull.in/segmentUpdation?mob='+this.mobile_num+'&seg='+this.exchangeSelect+'&tradeExp='+this.tradingExperience+'&qualification='+this.qualification+'&occupation='+this.occupation,
                                          headers: { }
                                        };
                                        var axiosThis = this
                                        axios(config)
                                        .then(function (response) {
                                          console.log(JSON.stringify(response.data));
                                          axiosThis.step=6
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                                      }
                                    },
                                    connectDigio(){
                                      var stateid = "signup" + Math.random().toString(36).substring(7);
                                      window.location.href =
                                        "https://api.digitallocker.gov.in/public/oauth2/1/authorize?response_type=code&client_id=2AF06457&state=" +
                                        stateid +
                                        "";
                                    },
                                    bankDetailUpdate(){
                                      this.$v.$touch()
                                            
                                      if(this.ifsc && this.accNo && this.bank_name)  {
                                           this.$v.$reset()
                                           var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/bankDetails?mob='+this.mobile_num+'&bankName='+this.bank_name+'&ifsc='+this.ifsc+'&accountNo='+this.accNo+'&micr='+this.bank_micr+'&branch='+this.bank_branch,
                                              headers: { }
                                            };
                                            var axiosThis = this 
                                            axios(config)
                                            .then(function (response) {
                                              console.log(JSON.stringify(response.data));
                                              axiosThis.step = 5
                                            })
                                            .catch(function (error) { 
                                              console.log(error);
                                            });
                                        }
                                    },
                                    fileUpload(){
                                      this.$v.$touch()
                                      console.log(typeof(this.panFile['type']),this.aadharFile['type'],this.bankFile)
                                      if (this.panFile && this.aadharFile && this.bankFile)
                                      { 
                                        this.cardloader = true;
                                        if((this.aadharFile['type'] == 'image/jpeg' || this.aadharFile['type'] == 'image/jpg' || this.aadharFile['type'] == 'image/png') && (this.panFile['type'] == 'image/jpeg' || this.panFile['type'] == 'image/jpg' || this.panFile['type'] == 'image/png') && (this.bankFile['type'] == 'image/jpeg' || this.bankFile['type'] == 'image/jpg' || this.bankFile['type'] == 'image/png')){ 

                                            this.$v.$reset()
                                            var axiosThis = this
                                            let formData = new FormData();
                                            console.log(this.panFile,this.aadharFile,this.bankFile)

                                            formData.append('panFile', this.panFile);
                                            formData.append('aadharFile', this.aadharFile);
                                            formData.append('bankFile', this.bankFile);
                                            formData.append('mob',atob(localStorage.getItem('hash')));
                                            
                                            console.log('>> formData >> ', formData);
                                            
                                            // You should have a server side REST API 
                                            axios.post('https://be.zebull.in/fileUpload?mob='+atob(localStorage.getItem('hash')),
                                                formData, {
                                                  headers: {
                                                    'Content-Type': 'multipart/form-data' 
                                                  }
                                                }
                                              ).then(function (response) {
                                                console.log('SUCCESS!!');
                                                axiosThis.digioFile = response.data['fileid']
                                                axiosThis.digioMail = response.data['mailid']
                                                localStorage.setItem('fileid',response.data['fileid'])
                                                console.log(response['fileid'],response['mailid'])
                                                axiosThis.cardloader=false;
                                                axiosThis.step =7;
                                              })
                                              .catch(function () {
                                                console.log('FAILURE!!');
                                              });
                                          }
                                        else{
                                           this.cardloader=false;
                                           this.toasttext = 'Please Upload all files in Image Format'
                                           this.snackbar = true
                                           this.$v.$reset()

                                        }
                                      }

                                    },
                                    digioEsign(){
                                        // var http = require('http');
                                        // var fs = require('fs');
                                        // var google = http.createClient(80, 'www.google.com');
                                        // var request = google.request('GET', '/sdk/v8/digio.js',
                                        //   {'host': 'app.digio.in'});
                                        // request.end();
                                        // out = fs.createWriteStream('digio.js');
                                        // request.on('response', function (response) {
                                        //   response.setEncoding('utf8');
                                        //   response.on('data', function (chunk) {
                                        //     out.write(chunk);
                                        //   });
                                        // });
                                        //var Digio = require('https://app.digio.in/sdk/v8/digio.js');
                                        console.log('File ID : '+this.digioFile)
                                        localStorage.setItem('fileid',this.digioFile)
                                        var options = {
                                        environment: "production",
                                            "callback": function(t){
                                                //console.log(t);
                                                if(t.hasOwnProperty('error_code'))
                                                {
                                                   // document.getElementById("loading").style.display='none';
                                                   // document.getElementById("result").innerHTML="failed to sign with error : "+t.message;
                                                   console.log('Error Digio')
                                                   console.log(t.message)
                                                }
                                                else
                                                {
                                                    //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
                                                    //successDigio();
                                                    console.log('Success Digio');
                                                    console.log(this.step,this.ekycStatus);
                                                    window.location.href ="?data="+JSON.stringify(t.message);
                                                }
                                            }
                                        };

                                          var digio = new Digio(options);
                                          digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
                                          digio.submit(this.digioFile, this.digioMail);
                                    },
                                    logout(){
                                              this.$v.$reset()
                                              this.loader=null
                                              this.panLoader=null
                                              this.loading=false
                                              this.mobile_num=null
                                              //this.errorMobNumber=false
                                              this.logstatus=false
                                              this.reference=null
                                              this.timerCount=120
                                              this.timerEnabled=false
                                              this.errorDescOTP=false
                                              this.resendText=false
                                              this.email=null
                                              this.emailDisp=false
                                              this.emailOtpDisp=false
                                              this.otpemail=null
                                              this.otp=null
                                              this.checkbox=false
                                              this.invalid=null
                                              this.text= `You will receive an OTP on your number`
                                              this.loadertext="Verify"
                                              this.timeout= 3000
                                              this.otpdisp= true
                                              this.mobdisp=true
                                              this.isShowing=false
                                              this.activePicker= null
                                              this.date= null
                                              this.menu= false
                                              this.panDetaildisp= true
                                              this.panFetchDetail= false
                                              this.panInput=null
                                              this.accNo= null
                                              this.ifsc= null
                                              this.bank_name= null
                                              this.bank_branch= null
                                              this.bank_micr= null
                                              this.enabledRegisteredAP= false
                                              this.regMemberName=null
                                              this.exchangeSelect= ['NSE']
                                              
                                              this.tradingExperience= null
                                              this.qualificationList=['Highschool' , 'Graduation' , 'Post Graduation' , 'Doctrate' , 'Prof. Degree' , 'Under High School' , 'Illiterate-Other'] 
                                              this.occupationList=['Private Sector','Public Sector','Government Service','Business','Professional Agriculturist','Retired','Housewife','Student','Forex Dealer','Other']     
                                              this.qualification=null
                                              this.occupation=null
                                              this.errorDescPAN=false
                                              this.KycFetchDisp=false
                                              this.dob=null
                                              this.aadharNo=null
                                              this.add1=null
                                              this.add2=null
                                              this.add3=null
                                              this.add4=null
                                              this.address=null
                                              this.country=null
                                              this.district=null
                                              this.state=null
                                              this.pinCode=null
                                              this.fatherName=null
                                              this.gender=null
                                              this.name=null
                                              this.panFile=null
                                              this.aadharFile=null
                                              this.bankFile=null
                                              this.kycLoader=false
                                              this.checkboxEsign=false
                                              this.digioFile=null
                                              this.digioMail=null
                                              this.ekycStatus=false
                                              this.step= 1
                                              this.digiInformationShow=false
                                              this.dialog=false
                                              localStorage.removeItem("hash");
                                              localStorage.removeItem("fileid");
                                              localStorage.removeItem("sesslog");
                        },
                        onSwitched: function (isSwitched) {
                                    console.log('dark mode is enabled :', isSwitched);
                                    if(isSwitched){
                                      this.$vuetify.theme.dark=true
                                    }
                                    else{
                                      this.$vuetify.theme.dark=false
                                    }
                        }
                        },
                        components: {
                          DarkModeSwitch
                        },
                        computed: {
                                currentTitle () {
                                  switch (this.step) {
                                    case 1: return 'Sign Up Now'
                                    case 2: return 'PAN'
                                    case 3: return 'Aadhar'
                                    case 4: return 'Bank Details'
                                    case 5: return 'Trading Preference'
                                    case 6: return 'File Upload'
                                    case 7: return 'E-Sign'
                                    default: return 'Account created'
                                  }
                                },
                                numberErrors () {
                                  const errors = []
                                  if (this.mobile_num)
                                  {
                                    if (this.mobile_num.length != 10)
                                    {
                                      errors.push('Please enter 10 Digit Mobile Number')
                                    }
                                  }
                                  console.log(this.$v.mobile_num.$dirty,this.$v.mobile_num.required);
                                  if (!this.$v.mobile_num.$dirty) return errors
                                  !this.$v.mobile_num.required && errors.push('Mobile number is required')
                                  return errors
                                },
                                otpErrors () {
                                  const errors = []
                                  console.log("OTP Valid : ",this.errorOTPDisp,this.otp)
                                  if(this.errorOTPDisp){
                                  
                                      errors.push('Enter OTP is invalid')
                                      return errors
                                  }
                                  else{
                                      console.log(this.$v.otp.$dirty,this.$v.otp.required);
                                      if (!this.$v.otp.$dirty) return errors
                                      !this.$v.otp.required && errors.push('OTP is required')
                                      return errors
                                  }
                                  
                                },
                                emailErrors () {
                                  const errors = []
                                  if (!this.$v.email.$dirty) return errors
                                  !this.$v.email.email && errors.push('e-mail is not valid')
                                  !this.$v.email.required && errors.push('E-mail is required')
                                  return errors
                                },
                                eotpErrors () {
                                  const errors = []
                                  if(this.erroreOTPDisp){
                                      errors.push('Enter OTP is invalid')
                                      return errors
                                  }
                                  else{ 
                                      console.log(this.$v.otpemail.$dirty,this.$v.otp.required);
                                      if (!this.$v.otpemail.$dirty) return errors
                                      !this.$v.otpemail.required && errors.push('OTP is required')
                                      return errors
                                  }
                                },
                                panErrors () {
                                  const errors = [] 
                                  if(this.errorDescPAN){
                                      errors.push('Enter PAN is invalid')
                                      return errors
                                  }
                                  else{
                                    console.log(this.$v.mobile_num.$dirty,this.$v.mobile_num.required);
                                    if (!this.$v.panInput.$dirty) return errors
                                    !this.$v.panInput.required && errors.push('PAN is required')
                                    return errors
                                  }
                                },
                            ifscErrors () {
                                  const errors = []
                                  console.log("Ifsc Error : ",this.ifscErrorDesc)
                                  if (this.ifsc)
                                  {
                                    if (this.ifsc.length != 11)
                                    {
                                      errors.push('Please enter 11 Digit IFSC Code')
                                    }
                                  }
                                  if(this.ifscErrorDesc){
                                      errors.push('Enter IFSC is invalid')
                                      return errors
                                  }
                                  else{
                                    console.log(this.$v.mobile_num.$dirty,this.$v.mobile_num.required);
                                    if (!this.$v.ifsc.$dirty) return errors
                                    !this.$v.ifsc.required && errors.push('IFSC code required')
                                    return errors
                                  }
                                },
                            accErrors () {
                                  const errors = []
                                  console.log(this.$v.mobile_num.$dirty,this.$v.mobile_num.required);
                                  if (!this.$v.accNo.$dirty) return errors
                                  !this.$v.accNo.required && errors.push('Account number required')
                                  return errors
                                },
                                rules() {
                                    console.log(this.exchangeSelect,this.exchangeSelect.length);
                                    if(this.exchangeSelect.length == 0){
                                      return [false]
                                    }
                                    else{
                                      return [true]
                                    }
                                    //return this.exchangeSelect.length > 0 || "At least one item should be selected"
                              
                                },
                                adharErrors () {
                                  const errors = []
                                  if (!this.$v.aadharFile.$dirty) return errors
                                  !this.$v.aadharFile.required && errors.push('Upload aadhar')
                                  return errors
                                },
                                panfErrors() {
                                  const errors = []
                                  if (!this.$v.panFile.$dirty) return errors
                                  !this.$v.panFile.required && errors.push('Upload PAN ')
                                  return errors
                                },
                                bankfErrors () {
                                  const errors = []
                                  if (!this.$v.bankfile.$dirty) return errors
                                  !this.$v.bankfile.required && errors.push('Upload Bank Proof')
                                  return errors
                                },
                                texpErrors() {
                                  console.log("Trading Exp :",this.tradingExperience)
                                  const errors = []
                                  if (this.tradingExperience)
                                  {
                                    if (this.tradingExperience >= 51)
                                    {
                                      errors.push('Max span is 50 years')
                                      return errors
                                    }
                                  }
                                  else{ 
                                      
                                      if (!this.$v.tradingExperience.$dirty) return errors
                                      !this.$v.tradingExperience.required && errors.push('Trading experience required')
                                      return errors
                                  }
                                },      
                                quaErrors  () {
                                  const errors = []
                                  if (!this.$v.qualification.$dirty) return errors
                                  !this.$v.qualification.required && errors.push('Qualification required')
                                  return errors
                                },   
                                occErrors () {
                                  const errors = []
                                  if (!this.$v.occupation.$dirty) return errors
                                  !this.$v.occupation.required && errors.push('Occupation required')
                                  return errors
                                },
                                regMemberNameErrors(){
                                  const errors = []
                                  if (!this.$v.regMemberName.$dirty) return errors
                                  console.log("Check boox :",this.enabledRegisteredAP)
                                  if(this.enabledRegisteredAP){
                                      !this.$v.regMemberName.required && errors.push('Broker Name required')
                                  }
                                  return errors
                                }
                        },
                        mounted(){
                              console.log(this.$route.query.code)
                              var dataFromDigi = this.$route.query.code;
                              var ekycstatus = this.$route.query.data;
                              if (dataFromDigi)
                              {
                                this.kycLoader=true
                                this.mobile_num = atob(localStorage.getItem('hash'))
                                this.step=3;
                                var config = {
                                          method: 'post',
                                          url: 'https://be.zebull.in/digiLockerFetch?mob='+this.mobile_num+'&code='+dataFromDigi,
                                          headers: { }
                                        };
                                        var axiosThis =this
                                        axios(config)
                                        .then(function (response) {
                                          console.log(JSON.stringify(response.data));
                                          var error = response.data['error']
                                          if(error){
                                              window.location.href ="/";
                                              axiosThis.kycLoader=false
                                          }
                                          axiosThis.kycLoader=false
                                          axiosThis.KycFetchDisp=true
                                          axiosThis.name = response.data['name']
                                          axiosThis.dob = response.data['DoB']
                                          axiosThis.aadharNo = response.data['aadharNo']
                                          axiosThis.address = response.data['address']
                                          axiosThis.add1 = response.data['add1']
                                          axiosThis.add2 = response.data['add2']
                                          axiosThis.add3 = response.data['add3']
                                          axiosThis.add4 = response.data['add4']
                                          axiosThis.country = response.data['country']
                                          axiosThis.district = response.data['district']
                                          axiosThis.fatherName = response.data['fatherName']
                                          axiosThis.pinCode = response.data['pincode']
                                          axiosThis.state =  response.data['states']
                                          axiosThis.gender = response.data['gender']
                                        })
                                        .catch(function (error) {
                                          console.log(error);
                                        });
                              }
                              else if(ekycstatus){
                                  console.log('ekyc status:',ekycstatus);
                                  this.step=8;
                                  this.ekycStatus=true;
                                  this.mobile_num = atob(localStorage.getItem('hash'))
                                  this.digioFile = localStorage.getItem('fileid')
                                  console.log('Disp : ',atob(localStorage.getItem('hash')),this.digioFile)
                                  var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/ekycUpdate?mob='+this.mobile_num+'&fileid='+this.digioFile,
                                              headers: { }
                                            };
                                            axios(config)
                                            .then(function (response) {
                                              console.log(JSON.stringify(response.data));
                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                            });
                              }
                              else{
                                  localStorage.removeItem("hash");
                                  localStorage.removeItem("fileid");
                                  localStorage.removeItem("sesslog");
                              }

                        },
                        watch: {
                                    loader () {
                                                  if (this.mobile_num){ 
                                                        if (this.mobile_num.length == 10)
                                                          {                                        
                                                            //this.errorMobNumber=false
                                                            const l = this.loader
                                                            this[l] = !this[l]
                                                            
                                                            setTimeout(() => (this[l] = false), 2000)

                                                            this.loader = null
                                                            if(this.emailDisp != true)
                                                            {
                                                              this.loadertext = "OTP Sent"
                                                              this.$v.$reset();
                                                              setTimeout(() => (this.mobdisp = false,this.otpdisp = false,this.timerEnabled = true), 4000)
                                                            }
                                                          }
                                                  }

                                    },
                                    panLoader() {
                                      const l = this.panLoader
                                      this[l] = !this[l]

                                      setTimeout(() => (this[l] = false), 2000)

                                      this.panLoader = null
                                    },
                                    menu (val) {
                                      val && setTimeout(() => (this.activePicker = 'YEAR'))
                                    },
                                    timerEnabled(value) {
                                        if (value) {
                                            setTimeout(() => {
                                                this.timerCount--;
                                            }, 1000);
                                        }
                                    },
                                    timerCount: {
                                        handler(value) {

                                            if (value > 0 && this.timerEnabled) {
                                                setTimeout(() => {
                                                    this.timerCount--;
                                                }, 1000);
                                            }
                                            if (value == 0){
                                              this.timerEnabled = false
                                              this.resendText = true
                                            }

                                        },
                                        immediate: true // This ensures the watcher is triggered upon creation
                                    }
                                    
                        },
  
  }
</script>
<style >
.inputPrice input[type='number'] {
    -moz-appearance:textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>


  